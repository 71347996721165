.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    color: #404c59; /* Main color */
  }
  
  .addCategoryBtn {
    padding: 10px 20px;
    background-color: #404c59; /* Main color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .addCategoryBtn:hover {
    background-color: #6e7883; /* Hover color */
  }
  
  .categoryTable {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .categoryTable thead {
    background-color: #404c59; /* Main color */
    color: white;
    text-align: left;
  }
  
  .categoryTable th,
  .categoryTable td {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .categoryTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .categoryTable tbody tr:hover {
    background-color: #e7e7e7; /* Light hover effect */
  }
  
  .categoryTable tr:hover {
    background-color: #6e7883; /* Hover color for rows */
    color: white; /* Change text color on hover */
  }
  
  .actionBtn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    margin-right: 5px;
    color: #404c59; /* Main color */
    transition: color 0.3s ease;
  }
  
  .actionBtn:hover {
    color: #6e7883; /* Hover color */
  }
  .deleteAllProducts{
    background-color: #404c59;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  