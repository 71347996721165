.title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
}

.likedContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 40px;
}

.noLikedItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    color: #6c757d; /* Subtle gray color */
    text-align: center;
    margin-top: 40px;
    font-weight: 500;
}

.sadIcon {
    font-size: 350px;
    color: #adb5bd; /* Light gray for the icon */
    margin-bottom: 10px;
}
@media (max-width: 768px) {
    .likedContainer {
        justify-content: center;
    }
    .sadIcon {
        font-size: 200px;
    }
    .noLikedItems {
        font-size: 20px;
    }
    
}
@media (max-width: 500px) {
   .title{
         font-size: 30px;
   }
    
}