.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.aboutUs {
    width: 1200px; /* Set to match the total width of the image container */
}

.aboutUsImages {
    display: flex;
    gap: 30px; /* Space between images */
    justify-content: center; /* Center images */
    align-items: center; /* Center images vertically */
}

.aboutUsImage {
    width: 400px; /* Fixed width for images */
    margin-top: 40px;
}

.title {
    font-size: 30px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    text-align: center;
}

.description {
    font-size: 18px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.leftSideContainer{
    font-size: 18px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}
@media (min-width:1140px) and (max-width: 1300px) {
    
    .aboutUsImage{
        width: 350px;
    }
    .aboutUs{
        width: 1100px;
    }
}

@media (min-width:1000px) and (max-width: 1139px) {
    
    .aboutUsImage{
        width: 300px;
    }
    .aboutUs{
        width: 950px;
    }
}

@media (min-width:835px) and (max-width: 999px) {
    
    .aboutUsImage{
        width: 250px;
    }
    .aboutUs{
        width: 800px;
    }
}

@media (min-width:700px) and (max-width: 834px) {
    
    .aboutUsImage{
        width: 200px;
    }
    .aboutUs{
        width: 650px;
    }
}

@media (min-width:500px) and (max-width:699px) {
    
    .aboutUsImage{
        width: 200px;
    }
    .aboutUs{
        width: 450px;
    }
    .description{
        font-size: 16px;
    }
    .title{
        font-size: 25px;
    }
}
@media (max-width:499px) {
    
    .aboutUsImage{
        width: 100%;
        
    }
    .aboutUs{
        width:  100%;
    }
    .description{
        font-size: 15px;
    }
    .title{
        font-size: 23px;
    }
    .container{
        padding: 0 20px;
    }
    
}
