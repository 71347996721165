.hero {
    display: flex;
    justify-content: center;
    padding: 20px; /* Add padding around the hero section */
    margin-top: 30px;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.top {
    display: flex;
    gap: 10px; 
    width: 100%;
}

.leftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.imageContainer{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    object-fit: cover;
}
.formaImage{
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.formaImage:hover{
    transform: scale(1.1);
}
.text {
    display: flex;
    justify-content: space-around; 
    align-items: center;
    gap: 10px;
    width: 100%;
}
.rightImage{
    height: 525px;
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.rightImage:hover{
    transform: scale(1.2);
}
.rightSide {
    display: flex;
    gap: 10px;
    align-self: flex-start;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
}
.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E9ECEF;
    padding: 35px;
    text-align: center;
    width: 100%; 
}

.title {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    margin-top: 10px; 
}

.icon {
    font-size: 40px;
    color: #000;
}

.bottom {
    display: flex;
    gap: 10px;
    width: 100%;
}
.rightSideBottom{
    display: flex;
    gap: 10px;
    width: 100%;
}
.titleContainerRight{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #CED4DA;
    text-align: center;
    width: 100%;
}
.leftImageContainer{
    width: 700px;
    height: fit-content;
    border-radius: 20px;
    object-fit: cover;
    overflow: hidden;

}
.left{
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.left:hover{
    transform: scale(1.1);
}

@media(min-width:1100px) and (max-width: 1325px) {
   .formaImage{
       width: 650px;
   }
   .rightImage{
       height: 470px;
   }
    .title{
        width: 100px;
    }
    .titleContainer{
        padding: 22px;
    }   
}
@media(min-width:1000px) and (max-width: 1099px) {
    .formaImage{
        width: 580px;
    }
    .rightImage{
        height: 435px;
    }
    .leftImageContainer{
        width: 645px;
    }
    .titleContainer{
        padding: 20px;
    }
    .title{
        width: 100px;
    }
}
@media (min-width: 850px) and (max-width: 999px) {
    .formaImage{
        width: 500px;
    }
    .rightImage{
        height: 380px;
    }
    .leftImageContainer{
        width: 500px;
        height: 150px;
    }
    .titleContainer{
        padding: 15px;
    }
    .title{
        width: 100px;
    }
    
}
@media (min-width: 600px) and (max-width: 849px) {
   .top{
       flex-direction: column;
       gap: 10px;
   }
   .text{
        width: 75%;
        justify-content: center;
    
   }
   .titleContainer{
        padding: 42px 5px;
   }
   .imageContainer{
       width: auto;
   }
   .formaImage{
    width: 520px;
   }
   .rightImage{
    width: auto;
    height: 600px;

   }
   .rightImage:hover{
    transform: scale(1.01);
   }
   .rightSide{
       width: 100%;
       justify-content: center;
   }
    .title{
        font-size: 15px;
   }
   
   .bottom{
         flex-direction: column;
         gap: 10px;
   }
   .leftImageContainer{
         display: flex;
         justify-content: center;
   }
   .left{
        width: 530px;
   }
   .left:hover{
    transform: scale(1.05);
    }
   .titleContainerRight{
        width: 24%;
        padding: 35px;
   }
   .rightSideBottom{
        justify-content: center;
   }
}
@media (min-width: 485px) and (max-width: 599.99px) {
    .top{
        flex-direction: column;
        gap: 10px;
    }
    .text{
         width: 76%;
         justify-content: center;
     
    }
    .titleContainer{
         padding: 42px 5px;
    }
    .imageContainer{
        width: auto;
    }
    .formaImage{
     width: 450px;
    }
    .rightImage{
     width: auto;
     height: 500px;
 
    }
    .rightImage:hover{
     transform: scale(1.01);
    }
    .rightSide{
        width: 100%;
        justify-content: center;
    }
     .title{
         font-size: 12px;
    }
    
    .bottom{
          flex-direction: column;
          gap: 10px;
    }
    .leftImageContainer{
          display: flex;
          justify-content: center;
          width: 585px;
    }
    .left{
         width: 445px;
    }
    .left:hover{
     transform: scale(1.05);
     }
    .titleContainerRight{
         width: 24%;
         padding: 35px;
    }
    .rightSideBottom{
         justify-content: center;
    }
 }

 @media (min-width: 360px) and (max-width:485px) {
    .top{
        flex-direction: column;
        gap: 10px;
    }
    .text{
         width: 77%;
         justify-content: center;
     
    }
    .titleContainer{
        padding: 13px 5px;
    }
    .imageContainer{
        width: auto;
    }
    .formaImage{
     width: 325px;
    }
    .rightImage{
     width: auto;
     height: 365px;
 
    }
    .rightImage:hover{
     transform: scale(1.01);
    }
    .rightSide{
        width: 100%;
        justify-content: center;
    }
     .title{
         font-size: 12px;
    }
    
    .bottom{
          flex-direction: column;
          gap: 10px;
    }
    .leftImageContainer{
          display: flex;
          justify-content: center;
          width: 415px;
    }
    .left{
         width: 325px;
    }
    .left:hover{
     transform: scale(1.05);
     }
    .titleContainerRight{
         width: 24%;
         padding: 10px;
    }
    .rightSideBottom{
         justify-content: center;
    }
    .icon{
        font-size: 30px;
    }
 }
 @media (min-width: 320px) and (max-width:359px) {
    .top{
        flex-direction: column;
        gap: 10px;
    }
    .text{
         width: 77%;
         justify-content: center;
     
    }
    .titleContainer{
        padding: 13px 5px;
    }
    .imageContainer{
        width: auto;
    }
    .formaImage{
     width: 275px;
    }
    .rightImage{
     width: auto;
     height: 315px;
 
    }
    .rightImage:hover{
     transform: scale(1.01);
    }
    .rightSide{
        width: 100%;
        justify-content: center;
    }
     .title{
         font-size: 12px;
    }
    
    .bottom{
          flex-direction: column;
          gap: 10px;
    }
    .leftImageContainer{
          display: flex;
          justify-content: center;
          width: 355px;
    }
    .left{
         width: 280px;
    }
    .left:hover{
     transform: scale(1.05);
     }
    .titleContainerRight{
         width: 24%;
         padding: 10px;
    }
    .rightSideBottom{
         justify-content: center;
    }
    .icon{
        font-size: 30px;
    }
 }