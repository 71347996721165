.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.icon{
    font-size: 250px;
    color: rgb(173, 181, 189);;
}
.title{
    font-size: 20px;
    color: rgb(108, 108, 108);
}