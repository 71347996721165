.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px 70px;
    background-color: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 900px;
    height: 650px;
    max-height: 80vh;
    overflow-y: auto;
}
.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.loadingSkeleton{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
.closeIcon{
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    font-size: 30px;
    
}
.image{
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 15px;
}
.cartText{
    font-size: 20px;
    font-weight: 500;
    text-align: start;
}
.mainTitle{
    font-size: 32px;
    font-weight: 600;
    text-align: start;
    letter-spacing: -2px;
    padding: 15px 0;
}
.sideText{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ADB5BD;
}
.mainContainer{
    display: flex;
    gap: 20px;
    padding: 10px 0;
    align-items: center;
}
.sideDataCell{
    text-align: center;
}
.itemName{
    font-size: 25px;
    font-weight: 500;
}
.itemSize{
    color: #6C757D;
    line-height: 25px;
    font-size: 13px;
}
.itemColor{
    color: #6C757D;
    font-size: 13px;
}
.quantityContainer{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.quantityButton{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ADB2B6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    font-size: 16px;
    transition: background-color 0.3s;
}
.quantityButton:hover{
    background-color: #6C757D;
}
.quantity{
    font-size: 20px;
    font-weight: 500;
}
.priceDataCell{
    text-align: end;
    font-size: 19px;
}
.priceText{
    font-size: 18px;
    font-weight: 500;
    text-align: end;
    color: #ADB5BD;
}
.tableRow{
    border-spacing: 10px;
}
.rectangleColor{
    width: 20px;
    height: 20px;
}
.colorContainer{
    padding: 3px;
    background-color: #e9ecef;
    width: fit-content;
}
.colorTextContainer{
    display: flex;
    gap: 5px;
    align-items: center;
}
.deleteIcon{
    font-size: 20px;
    cursor: pointer;
    color: #6C757D;
    transition: color 0.3s;
}
.deleteIcon:hover{
    color: #c4585e;
}
.totalText{
    font-size: 17px;
    font-weight: 400;
    text-align: end;
}
.totalPrice{
    font-size: 20px;
    font-weight: 600;
    text-align: end;
}
.checkoutButton{
    padding: 10px 20px;
    background-color: #454545;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}
.checkoutContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.returnText{
    font-size: 16px;
    font-weight: 500;
    color: #8c8c8c;
    cursor: pointer;
}
.arrowContainer{
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}
.arrowIcon{
    font-size: 20px;
    color: #8c8c8c;
}
.arrowIcon:hover{
    color: #454545;
}


.emptyCartIcon {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 50%;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

.emptyCartText {
    font-size: 24px;
    font-weight: 600;
    color: #6C757D;
}

.emptyCartSubtitle {
    font-size: 16px;
    color: #8c8c8c;
    margin-top: -10px;
}

.continueShoppingButton {
    padding: 10px 25px;
    font-size: 16px;
    color: #fff;
    background-color: #454545;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.continueShoppingButton:hover {
    background-color: #333333;
}
.emptyCartContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 25px 70px;
    background-color: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 900px;
    height: 650px;
    max-height: 80vh;
    overflow-y: auto;
}
@media (min-width:820px) and (max-width:930px){
    .container{
        width: 800px;
    }
    .emptyCartContainer{
        width: 800px;
    }
}
@media (min-width:723px) and (max-width:819px){
    .container{
        width: 700px;
    }
    .emptyCartContainer{
        width: 700px;
    }
    
}
@media (min-width:625px) and (max-width:722px){
    .container{
        width: 600px;
    }
    .itemName{
        font-size: 20px;
    }
    .emptyCartContainer{
        width: 600px;
    }
}
@media (min-width:460px) and (max-width:624px){
    .container{
        width: 100%;
        height: 100%;
        max-height: fit-content;
        padding: 25px 20px;
        top: 0;
        left: 0;
        transform: none;
    }
    .itemName{
        font-size: 14px;
    }
    .emptyCartContainer{
        width: 100%;
        height: 100%;
        max-height: fit-content;
        padding: 25px 20px;
    }
    .background{
        background-color: #FFFFFF;
    }
    .emptyCartSubtitle{
        width: 300px;
    }
}
@media (min-width:320px) and (max-width:459px){
    .container{
        width: 100%;
        height: 100%;
        max-height: fit-content;
        padding: 25px 10px;
        top: 0;
        left: 0;
        transform: none;
    }
    .itemName{
        font-size: 13px;
        width: 60px;
    }
    .image{
        width: 54px;
        height: 65px;
    }
    .mainContainer{
        gap: 10px;
    }
    .emptyCartContainer{
        width: 100%;
        height: 100%;
        max-height: fit-content;
        padding: 25px 10px;
    }
    .background{
        background-color: #FFFFFF;
    }
    .emptyCartSubtitle{
        width: 300px;
    }
    
}