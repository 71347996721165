.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    
    
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    color: #333;
  }
  
  .addProductBtn {
    padding: 10px 20px;
    background-color: #404c59;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .addProductBtn:hover {
    background-color: #6e7883;
  }
  
  .productTable {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .productTable thead {
    background-color: #404c59;
    color: white;
    text-align: left;
  }
  
  .productTable th,
  .productTable td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .productTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .productTable tr:hover {
    background-color: #f1f1f1;
  }
  
  .status {
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
  
  .active {
    background-color: #28a745;
  }
  
  .inactive {
    background-color: #dc3545;
  }
  
  .actionBtn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    margin-right: 5px;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .actionBtn:hover {
    color: #0056b3;
  }
  .descriptionCell {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}