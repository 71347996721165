.imageContainer {
    position: relative;
    width: 370px;
    height: 500px;
    overflow: hidden; /* Ensures zoomed image stays within bounds */
    border-radius: 20px;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; 
    cursor: pointer; /* Smooth transition for zoom effect */
}

.image:hover {
    transform: scale(1.1); /* Slight zoom effect */
}

.item {
    width: fit-content;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-size: 20px;
    font-weight: 400;
    max-width: 250px;
}

.price {
    font-size: 20px;
    font-weight: 400;
}

.contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.back {
    padding: 50px;
    background-color: white;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%); 
    z-index: 1000; /* Zajistí, že modal je nad ostatními elementy */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
}
.error {
    color: red;
    font-size: 13px;
    margin-bottom: 15px;
}
.closeButton{
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
}
.closeButton:hover{
    color: red;
}

.button {
    background-color: #ADB5BD;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: black;
}
.buttons{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.starContainer {
    position: absolute;
    top: 10px;
    left: 10px;
    transition: transform 0.2s ease; /* Smooth transition for the star */
    cursor: pointer; /* Make the star clickable */
}

.star {
    color: #FFCC00;
    font-size: 30px;
}

.starContainer:hover .star {
    transform: scale(1.2); /* Scale the star on hover */
}

.star.liked {
    color: #FFCC00; /* Fill color when liked */
}

.star.unliked {
    color: #FFCC00; /* Empty color when unliked */
}

.button:hover {
    background-color: #868E96;
}
.new{
    background-color: #34C724;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 15px;
    right: 0px;
    color: white;
    padding: 7px;
    border-radius: 25px 0 0 25px;
}
.buyNowButton{
    background-color: #ADB5BD;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: black;
}
@media (min-width: 400px) and (max-width: 500px) {
    .imageContainer {
        width: 300px;
        height: 430px;
    }

    .title {
        font-size: 16px;
    }

    .price {
        font-size: 16px;
    }

    .button {
        padding: 8px 20px;
        font-size: 15px;
    }
}
@media (min-width: 320px) and (max-width: 399px) {
    .imageContainer {
        width: 250px;
        height: 400px;
    }
    .title{
        width: 140px;
    }
}