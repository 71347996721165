.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .productContainer {
    display: flex;
    gap: 40px;
    align-items: start;
  }
.mainImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;

}
.thumbnailContainer{
    display: flex;
    gap: 10px;
    flex-direction: column;
    flex-wrap: wrap;
}
.mainImageContainer{
    width: 570px;
    height: 650px;
    border-radius: 20px;

}
.imagesContainer{
    display: flex;
    gap: 10px;
    margin-top: 20px;
}
.thumbnail{
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 20px;
}
.videoWrapper {
  position: relative;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 50%;
  font-size: 18px;
  pointer-events: none; /* Ensure it doesn't block clicks */
}
.title{
    font-size: 40px;
    margin-top: 40px;
    margin-bottom: 10px;
}
.description{
    font-size: 20px;
    max-width: 600px;
}
.colorSelector{
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
}


.colorText{
    font-size: 20px;
}
.sizeSelector{
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    flex-wrap: wrap;
}
.sizeButton{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    background-color: #CED4DA;
    font-size: 15px;
}
.sizeText{
    font-size: 20px;
}
.countButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.countButton {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    background-color: #ADB2B6;
    font-size: 16px;
    padding: 2px;
}

.count {
    font-size: 25px; /* Adjust font size as needed */
    font-weight: 500;
    color: #333; /* Adjust text color as needed */
}
.buyButton{
    background-color: #ADB5BD;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    color: black;
    font-weight: 500;
}
.buyContainer{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    justify-content: end;
}
.buyButton:hover{
    background-color: #868E96;
}
.colorSelector {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
  }
  
  .colorContainer {
    padding: 5px;
    border-radius: 10px;
    background-color: #f1f3f5; /* Light gray background for container */
    transition: border 0.3s ease;
  }
  
  .colorButton {
    width: fit-content;
    height: 30px;
    border-radius: 4px; /* Rectangle shape */
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: black;

  }
  
  .activeColorContainer {
    border: 1px solid black; /* Black border for the selected color */
    background-color: #f1f3f5; /* Slightly darker gray for selected background */
  }
  
.SliderTitle{
    font-size: 30px;
    margin-top: 40px;
    font-weight: bold;
    text-align: center;

}
.carouselContainer {
    margin-top: 40px;
    position: relative; /* Allows positioning of buttons relative to the container */
   /* Centers the carousel container */
  }
  
  .carouselItem {
    padding-right: 25px; /* Adds gap between items */
  }
  
  .arrow {
    background: transparent; /* No background */
    border: none; /* No border */
    font-size: 40px; /* Size of the arrow */
    color: black; /* Color of the arrow */
    cursor: pointer; /* Cursor style */
    position: absolute; /* Allows for positioning */
    top: 60%; /* Center vertically */
    transform: translateY(-50%); /* Adjust to center */
    z-index: 1; /* Ensure it's above the carousel */
    transition: color 0.3s; /* Smooth color transition */

  }
  .arrow:hover {
    color: #7b7b7b; /* Change color on hover */
    
  }
  
  .arrow:first-of-type {
    left: -40px; /* Offset for left arrow */
  }
  
  .arrow:last-of-type {
    right: -30px; /* Offset for right arrow */
  }
  .reviewTitle{
    font-size: 30px;
    margin-top: 40px;
    font-weight: bold;
    text-align: center;
  }
  .review {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 600px;
  }
  .reviewText{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    font-style: italic;

  }
  .reviewsContent{
    display: flex;
    gap: 30px;
  }
  .reviewContainer{
    border-right: 1px solid #ADB5BD;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
  }
  .reviewInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rating{
    margin-right: 30px;
  }
  .star{
    color: #FFCC00;
    font-size: 20px;
  }
  .showMoreButton{
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    color: #A3A3A3;
  }
  .addReview{
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-direction: column;
    background-color: #E9ECEF;
    padding: 30px;
    border-radius: 20px;
  }
  .reviewInput{
    width: 100%;
    height: 40px;
    border-radius: 30px;
    border: none;
    padding: 10px;
  }
  .reviewTextArea{
    width: 300px;
    height: 150px;
    border-radius: 20px;
    border: none;
    padding: 10px;
    resize: none;
  }
  .reviewLabel{
    margin-bottom: -10px;
    margin-left: 10px;
  }
  .ratingContainer{
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
  .ratingStar {
    color: #FFFFFF;
    font-size: 25px;
    cursor: pointer;
  }
  
  .ratingStar:hover {
    color: #FFD700; /* Lighter yellow for hover effect */
  }
  .addReviewButton{
    background-color: White;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
  .addReviewButtonContainer{
    display: flex;
    justify-content: end;
  }
  .errorText {
    color: red;
    font-size: 0.9rem;
    line-height: 10px;
  }
  .alert {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    text-align: center;
    font-size: 1rem;
  }
  
  
  .image {
    width: 250px;
    cursor: pointer;
    transition: transform 0.3s ease;
    border: 1px solid;
    padding: 10px;
  }
  
  .image:hover {
    transform: scale(1.05);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    position: relative;
    max-width: 50%;
  }
  
  .modalImage {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2rem;
    color: rgb(0, 0, 0);
    cursor: pointer;
    outline: none;
  }
  
  .closeButton:hover {
    color: red;
  }
  .sizeTitle{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  
  @media (min-width: 1200px) and (max-width:1375px) {
      .mainImageContainer{
        width: 450px;
        height: 620px;
      }
      .title{
        font-size: 38px;
      }
      .description{
        font-size: 18px;
        max-width: 520px;
      }
  }
  @media (min-width:990px) and (max-width:1199.99px){
      .mainImageContainer{
        width: 415px;
        height: 555px;

      }
      .title{
        font-size: 35px;

      }
      .description{
        font-size: 16px;
        max-width: 435px;
      }
      .productContainer{
        gap: 24px;

      }
      .buyContainer{
        justify-content: center;
      }
      .thumbnail{
        width: 75px;
        height: 75px;
      }
  }
  @media (min-width:700px) and (max-width:989.99px){
    .mainImageContainer{
      width: 330px;
      height: 450px;
    }
    .title{
      font-size: 35px;

    }
    .description{
      font-size: 15px;
      max-width: 300px;
    }
    .productContainer{
      gap: 24px;
      padding: 0 10px;

    }
    .buyContainer{
      justify-content: center;
    }
    .thumbnail{
      width: 75px;
      height: 75px;
    }
    .imagesContainer{
      flex-direction: column-reverse;
    }
    .thumbnailContainer{
      flex-direction: row;
      width: 350px;
    }
    .colorText{
      font-size: 15px;
    }
    .sizeText{
      font-size: 15px;
    }
    .colorButton{
      font-size: 13px;
    }
}
@media (min-width:400px) and (max-width:699.99px) {
  .mainImageContainer{
    width: 330px;
    height: 450px;
  }
  .productInfo{
    width: 330px;
  }
  .title{
    font-size: 35px;

  }
  .description{
    font-size: 15px;
    max-width: 470px;
  }
  .productContainer{
    gap: 0px;
    padding: 0 10px;
    flex-direction: column;

  }
  .buyContainer{
    justify-content: center;
  }
  .thumbnail{
    width: 75px;
    height: 75px;
  }
  .imagesContainer{
    flex-direction: column-reverse;
  }
  .thumbnailContainer{
    flex-direction: row;
    width: 350px;
  }
  .colorText{
    font-size: 15px;
  }
  .sizeText{
    font-size: 15px;
  }
  .colorButton{
    font-size: 13px;
  }
  .colorSelector{
    flex-wrap: wrap;
  }
}
@media (max-width:399.99px) {
  .mainImageContainer{
    width: 275px;
    height: 385px;
  }
  .productInfo{
    width: 275px;
  }
  .title{
    font-size: 30px;

  }
  .description{
    font-size: 14px;
    max-width: 470px;
  }
  .productContainer{
    gap: 0px;
    padding: 0 10px;
    flex-direction: column;

  }
  .buyContainer{
    justify-content: center;
  }
  .thumbnail{
    width: 75px;
    height: 75px;
  }
  .imagesContainer{
    flex-direction: column-reverse;
  }
  .thumbnailContainer{
    flex-direction: row;
    width: 350px;
  }
  .colorText{
    font-size: 14px;
  }
  .sizeText{
    font-size: 14px;
  }
  .colorButton{
    font-size: 12px;
  }
  .colorSelector{
    flex-wrap: wrap;
  }
}
@media (min-width:950px) and (max-width:1050px){
  .review{
    width: 500px;
  }
}
@media (min-width:850px) and (max-width:949.99px){
  .review{
    width: 400px;
  }
}
@media (min-width:500px) and (max-width:849.99px){
  .review{
    width: 400px;
  }
  .reviewsContent{
    flex-direction: column;
  }
  .reviewContainer{
    border-left: 1px solid #ADB5BD ;
    padding-left: 15px;
  }
  .reviewTextArea{
    width: 360px;
  }
}
@media (min-width:400px) and (max-width:499.99px){
  .review{
    width: 340px;
  }
  .reviewsContent{
    flex-direction: column;
  }
  .reviewContainer{
    border-left: 1px solid #ADB5BD ;
    padding-left: 15px;
  }
}
@media (max-width:400px){
  .review{
    width: 260px;
  }
  .reviewsContent{
    flex-direction: column;
  }
  .reviewContainer{
    border-left: 1px solid #ADB5BD ;
    padding-left: 15px;
  }
  .reviewTextArea{
    width: 220px;
  }
  .rating{
    margin-right: 5px;
  }
  .star{
    font-size:16px
  }
  .errorText{
    font-size: 12px;
    width: 100px;
  }
  .thumbnailContainer{
    flex-direction: row;
    width: 275px;
  }
}