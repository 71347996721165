.addCategoryContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.addCategoryTitle {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.addCategoryForm {
    display: flex;
    flex-direction: column;
}

.addCategoryFormGroup {
    margin-bottom: 15px;
}

.addCategoryLabel {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

.addCategoryInput {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.addCategoryInput:focus {
    border-color: #404c59;
    outline: none;
}

.inputError {
    border-color: #dc3545; /* Red border for error */
}

.errorMessage {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
}

.addCategoryButton {
    padding: 10px 20px;
    background-color: #404c59;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.addCategoryButton:hover {
    background-color: #6e7883;
}

/* Alert message styling */
.alertMessage {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

.alertSuccess {
    background-color: #28a745;
}

.alertError {
    background-color: #dc3545;
}
