.addProductContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .addProductTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .addProductForm {
    display: flex;
    flex-direction: column;
  }
  
  .addProductFormGroup {
    margin-bottom: 16px;
  }
  
  .addProductLabel {
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .addProductInput {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .addProductTextArea {
    width: 100%;
    height: 100px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .inputError {
    border-color: red;
  }
  
  .errorText {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .addProductSubmitBtn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addProductSubmitBtn:hover {
    background-color: #0056b3;
  }
  .colorChoose{
    text-align: center;
    font-size: 24px;
    color: #454545;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .addColorBtn{
    padding: 10.5px 20px;
    background-color: #454545;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
 .AddExistingColor{
    padding: 10px 20px;
    background-color: #fff;
    color: #454545;
    border: 1px solid #454545;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

}
.buttonsContainer{
    display: flex;
    gap: 20px;
}
.colorRectangle{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.selectColorInput{
    width: fit-content;
}
.existingColor{
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}
.addColor{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.colorInput::-webkit-color-swatch-wrapper {
  padding: 6px;
}

.colorInput::-webkit-color-swatch {
  border: none;
}
.addColorContainer{
  width: fit-content;
  border-left: 1px solid #454545;
  padding-left: 20px;
  border-right: 1px solid #454545;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table{
  border-collapse: collapse;
}
.table th, .table td{
  border: 1px solid #ccc;

}
.stockInput,
.sizeInput{
  border: none;
  background-color: transparent;
  margin-left: 10px;
  width: 100px;
}
.stockInput:focus,
.sizeInput:focus{
  outline: none;
}
.head{
  background-color: #454545;
  color: #fff;
}
.head th{
  padding: 10px;
  font-weight: 400;
}
.inputCell {
  padding: 7px;
}
.addRemoveButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.addColorFormGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addSizeButton,
.removeSizeButton{
  padding: 5px 10px;
  background-color: #454545;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}
.addSizeLabel{
  font-weight: bold;
  margin-bottom: 4px;
  text-align: center;
}
.addSizeFormGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #007bff;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.dropzone:hover {
  border-color: #007bff;
}

.fileList {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
}

.fileItem {
  background: #f1f1f1;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.imagePreview {
  max-width: 200px; /* Adjust as needed */
  max-height: 200px; /* Adjust as needed */
  margin-top: 10px;
  border-radius: 8px;
  object-fit: cover;
}
.addImageFormGroup{
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.multipleContainerColor{
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 20px;
  flex-wrap: wrap;
}
.addRemoveColorButtons{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.addImageProductTitle{
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
  color: #454545;
}
.otherMediaImage{
  max-width: 200px; 
  max-height: 200px; 
  object-fit: cover;
}
.otherMediaVideo{
  max-width: 200px; 
  max-height: 200px; 
  object-fit: cover;
}
.otherMediaContainer{
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.otherMediaItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.removeMediaButton{
  padding: 5px 10px;
  background-color: #454545;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}