.colorChoose{
    text-align: center;
    font-size: 24px;
    color: #454545;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .addColorBtn{
    padding: 10.5px 20px;
    background-color: #454545;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
 .AddExistingColor{
    padding: 10px 20px;
    background-color: #fff;
    color: #454545;
    border: 1px solid #454545;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

}
.buttonsContainer{
    display: flex;
    gap: 20px;
}
.colorRectangle{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.selectColorInput{
    width: 200px;

}
.existingColor{
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}
.addColor{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.colorInput {
 width: 200px;
  padding: 5px;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.colorInput::-webkit-color-swatch-wrapper {
  padding: 6px;
}

.colorInput::-webkit-color-swatch {
  border: none;
}
.addColorContainer{
  width: fit-content;
  border-left: 1px solid #454545;
  padding-left: 20px;
  border-right: 1px solid #454545;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table{
  border-collapse: collapse;
}
.table th, .table td{
  border: 1px solid #ccc;

}
.stockInput,
.sizeInput{
  border: none;
  background-color: transparent;
  margin-left: 10px;
  width: 100px;
}
.stockInput:focus,
.sizeInput:focus{
  outline: none;
}
.head{
  background-color: #454545;
  color: #fff;
}
.head th{
  padding: 10px;
  font-weight: 400;
}
.inputCell {
  padding: 7px;
}
.addRemoveButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.addColorFormGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addSizeButton,
.removeSizeButton{
  padding: 5px 10px;
  background-color: #454545;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}
.addSizeLabel{
  font-weight: bold;
  margin-bottom: 4px;
  text-align: center;
}
.addSizeFormGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #007bff;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.dropzone:hover {
  border-color: #007bff;
}

.fileList {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
}

.fileItem {
  background: #f1f1f1;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.imagePreview {
  max-width: 200px; /* Adjust as needed */
  max-height: 200px; /* Adjust as needed */
  margin-top: 10px;
  border-radius: 8px;
  object-fit: cover;
}
.addImageFormGroup{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorText {
  color: red;
  font-size: 13px;
  margin-top: 4px;
}
.colorNameInput{
  width: 200px;
  border: 1px solid #b9b9b9;
  
  padding: 5px;
  background-color: transparent;

}