.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  
  .pageButton {
    padding: 16px 20px;
    border: 1px solid #ddd;
    background-color: #DEE2E6;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s;
    color: #000000;
  }
  
  .pageButton:hover {
    background-color: #CED4DA;
  }
  
  .pageButton:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .active {
   border: 1px solid #000000;
   background-color: #CED4DA;
  }
  
  .ellipsis {
    padding: 8px 0;
  }
  