.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }
  
  .modal p {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .modalActions {
    display: flex;
    justify-content: space-around;
  }
  
  .confirmBtn {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancelBtn {
    background-color: #5bc0de;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirmBtn:hover, .cancelBtn:hover {
    opacity: 0.8;
  }
  