.imageWrapper {
    width: 370px;
    height: 500px;
    overflow: hidden; /* Hides overflow for zoomed image */
    border-radius: 20px;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer; /* Smooth transition for zoom effect */
}

.image:hover {
    transform: scale(1.1); /* Scale image on hover */
}


.item{
    width: fit-content;
}
.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.title{
    font-size: 19px;
    font-weight: 400;
    max-width: 250px;
}
.price{
    font-size: 19px;
    font-weight: 400;
}
.new{
    background-color: #34C724;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 15px;
    right: 18px;
    color: white;
    padding: 7px;
    border-radius: 25px 0 0 25px;
}
@media (min-width: 400px) and (max-width: 500px){
    .imageWrapper {
        width: 300px;
        height: 430px;
    }
    .title{
        font-size: 16px;
    }
    .price{
        font-size: 16px;
    }
    .new{
        font-size: 11px;
        padding: 5px;
        top: 10px;
        right: 13px;
    }
    
}
@media (max-width:399.99px){
    .imageWrapper {
        width: 240px;
        height: 400px;
    }
    .title{
        font-size: 14px;
        max-width: 200px;
    }
    .price{
        font-size: 14px;
    }
    .new{
        font-size: 10px;
        padding: 4px;
        top: 8px;
        right: 10px;
    }
}