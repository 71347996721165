.title{
    font-size: 30px;
    text-align: center;
    color: #545454;
    margin-top: 40px;
}
.result{
   color: #2e2e2e;
}
.searchContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 20px;
}
@media screen and (max-width: 500px){
    .title{
        font-size: 23px;
    }
    
}