.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.content {
    text-align: center;
    width: fit-content;
}

.title {
    font-size: 40px;
    font-weight: 700;
    margin-top: 20px;
}

.description {
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
}

.button {
    background-color: #ADB5BD;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}
