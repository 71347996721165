.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer; /* Smooth transition for zoom effect */
}
.image:hover{
    transform: scale(1.1); /* Slight zoom effect */
}
.imageContainer{
    width: 400px;
    height:500px;
    overflow: hidden;
    border-radius: 30px;
}
.contentContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.container{
    width: fit-content;
}
.star{
    color: #FFCC00;
    font-size: 32px;
    transition: transform 0.2s ease; /* Smooth transition for the star */
    cursor: pointer;
}
.star:hover{
    transform: scale(1.1); /* Slight zoom effect */
}
.title{
    font-size: 23px;
    font-weight: 400;
    
    max-width: 250px;
}
.button{
    background-color: #ADB5BD;
    border: none;
    padding: 8px 25px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
}
.back {
    padding: 50px;
    background-color: white;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%); 
    z-index: 1000; /* Zajistí, že modal je nad ostatními elementy */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
}
.new{
    background-color: #34C724;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 15px;
    right: 0px;
    color: white;
    padding: 7px;
    border-radius: 25px 0 0 25px;
}
.buyNowButton{
    background-color: #ADB5BD;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: black;
}
.closeButton{
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
}
@media (min-width: 400px) and (max-width: 500px){
    .imageContainer {
        width: 300px;
        height: 430px;
    }
    .title{
        font-size: 16px;
    }
    .button{
        padding: 8px 20px;
        font-size: 13px;
    }
    
}
@media (min-width:320px) and (max-width: 399px){
    .imageContainer {
        width: 250px;
        height: 400px;
    }
    .title{
        font-size: 14px;
    }
    .button{
        padding: 8px 15px;
        font-size: 11px;
    }
}