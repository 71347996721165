.title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 80px;
  }
  
  .carouselContainer {
    position: relative; /* Allows positioning of buttons relative to the container */
    margin: 0 auto; /* Centers the carousel container */
  }
  
  .carouselItem {
    padding-right: 25px; /* Adds gap between items */
  }
  
  .arrow {
    background: transparent; /* No background */
    border: none; /* No border */
    font-size: 40px; /* Size of the arrow */
    color: black; /* Color of the arrow */
    cursor: pointer; /* Cursor style */
    position: absolute; /* Allows for positioning */
    top: 60%; /* Center vertically */
    transform: translateY(-50%); /* Adjust to center */
    z-index: 1; /* Ensure it's above the carousel */
    transition: color 0.3s; /* Smooth color transition */

  }
  .arrow:hover {
    color: #7b7b7b; /* Change color on hover */
    
  }
  
  .arrow:first-of-type {
    left: -40px; /* Offset for left arrow */
  }
  
  .arrow:last-of-type {
    right: -30px; /* Offset for right arrow */
  }
  