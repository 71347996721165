.filterContainer {
    padding: 30px;
    background-color: #ededed;
    border-radius: 0 10px 10px 0;
    height: 400px;
    position: sticky;
    top: 30px;
    left: 0;
    width: fit-content;
    overflow-y: auto;
  }
  
  .filterTitle {
    font-size: 30px;
    margin-bottom: 20px;

  }
  
  .filterItem {
    margin-bottom: 20px;
  }
  
  .filterItemTitle {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .inputContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .rangeSeparator {
    font-size: 18px;
    margin: 0 5px;
  }
  /* Add to Filter.module.css */

.colorContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.colorSwatch {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.colorSwatch:hover {
  transform: scale(1.1);
}

.selectedColor {
  border-color: #000000; /* Highlight selected color with a black border */
}

.colorSwatchContainer{
  padding: 2px;
}
.sizeContainer {

  width: 300px;
}

.sizeLabel {
  font-size: 16px;
  color: #343a40;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.selectedColor {
  border-color: #000; /* Highlight selected color with a black border */
}
.clearFiltersBtn{
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}
.colorContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 300px;
}

.colorButton {
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  text-align: center;
  min-width: 50px;
  transition: transform 0.2s ease, background-color 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #000;
}

.colorButton:hover {
  transform: scale(1.1);
}

.selectedColorButton {
  background-color: #bbbbbb; /* Selected state color */
  color: #000;
}
