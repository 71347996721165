.footer{
    background-color: #CED4DA;
    width: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    
}
.website{
    display: flex;
    align-items: center;
    gap: 50px;
    
}
.logo{
    width: 120px;   
}
.number{
    font-weight: bold;
    font-size: 30px;
    border-left: 1px solid #000;
    padding-left: 10px;
    border-right: 1px solid #000;
    padding-right: 10px;
}

.icon{
    font-size: 30px;
    color: #000;
}
.icons{
    display: flex;
    gap: 15px;
    
}
.copyright{
    font-size: 15px;
    font-weight: 400;
    color: #000;
    border-left: 1px solid #000;
    padding-left: 10px;
}
.title{
    font-size: 21px;
    font-weight: 600;
}
.linksContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.link{
    color: #454545;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}
.links{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    
}
.footerContainer{
    width: fit-content;
}
@media (max-width: 768px){
    .links{
        flex-direction: column;
        gap: 20px;
    }
    .number{
        border: none;
    }
    .copyright{
        border: none;
    }
    .website{
        flex-direction: column;
        gap: 20px;
    }
}