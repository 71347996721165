/* AdminOrders.module.css */
.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    color: rgb(57, 57, 57);
    margin-bottom: 20px;
  }
  
  .orderTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .orderTable th, .orderTable td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .orderTable thead {
    background-color: #404c59; /* Main color */
    color: white;
  }
  
  .orderTable tbody tr:hover {
    background-color: #e7e7e7; /* Light hover effect */
  }
  .modalButton {
    background-color: #404c59; /* Main color */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 5px;
  }
  .icon{
    font-size: 20px;
    color: #404c59; /* Main color */
    cursor: pointer;
    margin-left: 10px;
  }
  .delete{
    color: #d9534f;
    font-size: 30px;
    cursor: pointer;
  }
  .delete:hover{
    color: #c9302c;
  }