.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f5f5f5; /* Світло-сірий */
    padding: 10px;
  }
  
  .loginForm {
    background: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%; /* Щоб форма займала всю ширину на маленьких пристроях */
    box-sizing: border-box;
  }
  
  .loginTitle {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .errorMessage {
    color: #ff6b6b;
    background: #ffe6e6;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .fieldError {
    color: #ff6b6b;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
  }
  
  .input:focus {
    border-color: #333;
    outline: none;
  }
  
  .loginButton {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .loginButton:hover {
    background-color: #555;
  }
  