.filterButton {
  padding: 10px 15px;
    background-color: #ADB5BD;
    width: fit-content;
    font-size: 20px;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    outline: none;
    border: none;
    color: #333;
}
.hiddenFilter {
  translate: -500px 0;
  transition: 500ms;
  display: none;
}
.showFilter {
  translate: 0 0;
  transition: 500ms;
}
.title{
    font-size: 40px;
    text-align: center;
    margin-top: 40px;
}
.mainContainer{
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 50px;
}
.filterContainer{
    position: fixed;
    left: 0;
    top: 120px;
    z-index: 100;
    width: fit-content;
}
.sortContainer{
    display: flex;
    justify-content: end;
    gap: 10px;
    align-items: center;
}
.rightContainer{
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: fit-content;

}
.row {
    display: flex;
    gap: 20px;
    justify-content: center; /* Centers the items in each row */
  }
  .categoryContainer {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between each row */
    
  }

  
  .sortButton {
    padding: 8px 12px;
    margin-right: 8px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    background-color: white;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sortButton:hover:not(:disabled) {
    background-color: #f1f1f1; /* Hover color for non-disabled buttons */
  }
  
  .sortButton:disabled {
    background-color: #CED4DA; /* Selected button background */
    color: #fff; /* Selected button text color */
    border: 1px solid #CED4DA;
    cursor: not-allowed;
  }
  
  .sortButton:not(:disabled):focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Optional focus ring */
  }

  @media (max-width:470px){
    .title{
      font-size: 28px;
    }
  }