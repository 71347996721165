.sidebar {
    background-color: #333;
    color: white;
    padding: 20px;
    height: 100vh; /* Full height of the viewport */
    
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 20px 0;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar ul li a:hover,
  .activeLink {
    text-decoration: underline;
    font-weight: bold;
  }
  
  .title{
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
  }
  .homeIcon{
    font-size: 2rem;
    margin-right: 10px;
    cursor: pointer;
  }
  .homeIcon:hover{
    color: #bcbcbc;
    transition: 0.3s ease-out;
  }
  .logoutIcon{
    font-size: 2rem;
    margin-right: 10px;
    cursor: pointer;
  }
  .logoutIcon:hover{
    color: #bcbcbc;
    transition: 0.3s ease-out;
  }