.adminPanel {
  display: flex;

  position: relative;
}

.adminPanel * {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0; /* Always visible on larger screens */
  width: 250px;
  height: 100vh;
  background-color: #333;
  color: white;
  overflow-y: auto;
  transition: none; /* No transition on larger screens */
  z-index: 1000;
}

.sidebarOpen {
  transform: translateX(250px); /* Will be used for mobile only */
}

.content {
  flex: 1; /* Take up the remaining space after the sidebar */
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: auto; /* Ensure the content can scroll */
  margin-left: 250px; /* Sidebar margin on larger screens */
  transition: margin-left 0.3s ease-in-out;
}

/* Button to toggle sidebar visibility, visible only on mobile screens */
.toggleButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1100;
    background-color: #454545;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: none;
    font-size: 20px;
}

/* Show toggle button and animate sidebar only on mobile (below 768px) */
@media (max-width: 768px) {
  .toggleButton {
    display: block; /* Visible on smaller screens */
  }

  .content {
    margin-left: 0; /* No margin when sidebar is hidden on mobile */
  }

  .sidebar {
    left: -250px; /* Sidebar is hidden off-screen by default */
    transition: transform 0.3s ease-in-out; /* Smooth transition for sliding */
  }

  .sidebarOpen {
    transform: translateX(250px); /* Sidebar slides in on toggle */
  }

  .content {
    margin-left: 0; /* No sidebar margin on mobile */
  }
}
