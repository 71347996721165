/* SkeletonLoader.module.css */
.skeletonContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  
  .skeletonItem {
    width: 370px;
    height: 360px;
    background-color: #ffffff00; /* Gray background color */
    border-radius: 8px;
    animation: shimmer 1.5s infinite ease-in-out;
  }
  
  /* @keyframes shimmer {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  } */
  