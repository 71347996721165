/* ThanksForOrder.module.css */

.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's on top of everything */
    animation: fadeIn 0.8s ease-in-out; /* Add fade-in animation */
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.thankYouContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thankYouContainer h1 {
    font-size: 32px;
    color: #333;
    margin-top: 20px;
}

.thankYouContainer p {
    font-size: 16px;
    color: #666;
}

.checkMark {
    width: 80px;
    height: 80px;
    background-color: #4CAF50;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}

.checkMark::before {
    content: '';
    position: absolute;
    left: 32px;
    top: 21px;
    width: 12px;
    height: 24px;
    border: solid white;
    border-width: 0 5px 5px 0;
    transform: rotate(45deg);
}
.title{
    font-size: 29px;
    color: #333;
    margin-top: 20px;
}
.subtitle{
    font-size: 17px;
    color: #666;
    margin-top: -22px;
}

@keyframes checkMarkAnimation {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
