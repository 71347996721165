.title{
    font-size: 30px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: -2px;
    text-align: center;
    margin-bottom: 30px;
}
.cartTitle{
    margin-top: 38px;
}
.description{
    font-size: 15px;
    font-weight: 300;
    margin-left: 40px;
    letter-spacing: -1px;
    color: #666;
}
.row{
    display: flex;
    flex-direction: column;
    width: 300px;
}
.label{
    color: #6C757D;
    font-size: 18px;
    font-weight: 400;
}
.input{
    width: 100%;
    height: 40px;
    background-color: #E9ECEF;
    border-radius: 5px;
    padding-left: 10px;
    border: none;
}
.form{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 30px;
    border-right: 2px solid #E9ECEF;
    padding-right: 40px;
}
.rowContainer{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.emailInput{
    width: 100%;
    height: 40px;
    background-color: #E9ECEF;
    border-radius: 5px;
    padding-left: 10px;
    border: none;
}
.userInfo{
    width: 500px;
}
.subtitle{
    margin-bottom: 20px;
    
}
.novaPoshtaContainer{
    background-color: #E9ECEF;
    border-radius: 5px;
    padding: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.novaPoshtaLogo{
    width: 150px;
  
}
.deliverySelect{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.typeTitle{
    margin-top: 20px;
}
.deliveryTypeOptions{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.deliveryWarehouse{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.select{
    width: 500px;
    
}
.streetAndHouse{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.infoText{
    margin-bottom: 30px;
}
.courierInfo{
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 20px;
}
.cartItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 700px;
    max-height: 500px;
    overflow-y: auto;
}

.cartItem {
    display: flex;
    align-items: center;
    background-color: #F8F9FA;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 20px;
}

.itemImage {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.itemTitle {
    font-size: 16px;
    font-weight: 500;
    color: #343A40;
}

.itemPrice {
    font-size: 14px;
    font-weight: 400;
    color: #28A745;
}

.itemColor, .itemSize, .itemQuantity {
    font-size: 14px;
    color: #6C757D;
}

.itemQuantity {
    font-weight: 400;
    margin-top: 5px;
}
.checkoutContainer{
    display: flex;
    justify-content: center;
    gap: 50px;
    
}
.totalPrice {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
    text-align: right;
    padding: 10px;
  }
  
  .submitButton {
    background-color: #6e7883; /* Customize button color */
    color: white;
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    width: fit-content;
    margin: 20px auto;
    text-align: center;
  }
  
  .submitButton:hover {
    background-color: #a4a9ae;
  }
  .paymentOptions {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 700px;
  }
  
  .paymentOption {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
    border: 2px solid transparent;
  }
  
  .paymentOption:hover {
    transform: translateY(-2px);
  }
  
  .selectedPayment {
    border-color: #28a745;
    background-color: #e9f7ef;
  }
  
  .icon {
    font-size: 2rem;
    color: #6c757d;
  }
  
  .paymentOption h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #343a40;
    margin: 0;
  }
  
  .paymentOption p {
    font-size: 0.9rem;
    color: #6c757d;
    margin: 5px 0 0 0;
  }
  .error{
    color: red;
    font-size: 14px;
  }
  .darkTransparentBackground{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .googlePayModal{
    background-color: white;
    padding: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  .closeIcon{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  @media (min-width:1340px) and (max-width:1450px){
    .cartItems{
        width: 600px;
    }
    .paymentOptions{
        width: 600px;
    }
    .paymentOption h3{
        font-size: 1.1rem;
    }
  }
  @media (min-width:1200px) and (max-width:1339.99px){
    .cartItems{
        width: 500px;
    }
    .paymentOptions{
        width: 500px;
    }
    .paymentOption h3{
        font-size: 1.1rem;
    }
    .select{
       width: 430px;
    }
    .userInfo{
        width: 430px;
    }
    .novaPoshtaContainer{
        width: 430px;
    }
    .totalPrice{
        font-size: 1.3rem;
    }
    .courierInfo{
        width: 430px;
    }
  }
  @media (min-width:1000px) and (max-width:1199.99px){
    .cartItems{
        width: 400px;
    }
    .paymentOptions{
        width: 400px;
    }
    .paymentOption h3{
        font-size: 1.1rem;
    }
    .select{
       width: 370px;
    }
    .userInfo{
        width: 370px;
    }
    .novaPoshtaContainer{
        width: 370px;
    }
    .totalPrice{
        font-size: 1.3rem;
    }
    .courierInfo{
        width: 370px;
    }
  }
  @media (min-width:600px) and (max-width:999.99px){
    
     .cartItems{
        width: 560px;
     }
     .paymentOptions{
        width: 560px;
     }
     .checkoutContainer{
        flex-direction: column;
        align-items: center;
     }
     .form{
        padding-right: 0px;
        border: none;
     }
  }
  @media (min-width:400px) and (max-width:599.99px){
    .googlePayModal{
        padding: 100px 40px;
    }
    .cartItems{
       width: 370px;
    }
    .paymentOptions{
       width: 370px;
       flex-direction: column;
    }
    .checkoutContainer{
       flex-direction: column;
       align-items: center;
    }
    .form{
       padding-right: 0px;
       border: none;
    }
    .select{
        width: 370px;
     }
     .userInfo{
         width: 370px;
     }
     .novaPoshtaContainer{
         width: 370px;
     }
     .courierInfo{
        width: 370px;
     }
 }
 @media (max-width:399.99px) {
    .googlePayModal{
        padding: 100px 20px;
    }
    .cartItems{
       width: 280px;
    }
    .paymentOptions{
       width: 280px;
       flex-direction: column;
    }
    .checkoutContainer{
       flex-direction: column;
       align-items: center;
    }
    .form{
       padding-right: 0px;
       border: none;
    }
    .select{
        width: 280px;
     }
     .userInfo{
         width: 280px;
     }
     .novaPoshtaContainer{
         width: 280px;
     }
     .courierInfo{
        width: 280px;
     }
     .totalPrice{
        font-size: 1.1rem;
     }
     .title{
        font-size: 24px;
     }
     .subtitle{
        font-size: 18px;
     }
     .label{
        font-size: 16px;
     }
     .submitButton{
        font-size: 14px;
        padding: 15px;
     }
 }