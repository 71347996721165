.header {
    background-color: #CED4DA;
    position: relative;
}

.logo {
    width: 120px;
}

.mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 80px;
    padding: 3px 0;
}

/* Make the wrapper flexible and control padding here */
.searchWrapper {
    display: flex;
    align-items: center;
    background-color: #DEE2E6;
    border-radius: 30px;
    padding: 10px 15px; /* Add padding around the search bar */
    width: 600px; /* Optional: Control max width if needed */
}

.search {
    flex-grow: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 19px;
    font-weight: 500;
    color: #525252;
    padding-right: 10px; /* Space between text and icon */
}

.searchIcon {
    font-size: 22px;
    color: #000;

}
.content {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-right: 80px;
}
.icons{
    display: flex;
    gap: 15px;
}
.categoryContainer{
    background-color: #E9ECEF;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: center;
}
.navigation{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px 0;
        list-style: none;
        font-size: 18px;
        width: 90%;
        flex-wrap: wrap;
        gap: 11px;
}
.link{
    text-decoration: none;
    color: #000;
}
.searchDropdown {
    position: absolute;
    top: calc(100% + 5px); /* Positions the dropdown just below the input with some spacing */
    left: 0;
    width: 100%; /* Sets dropdown width to match input width */
    max-width: 600px; /* Optional: sets a max-width to limit dropdown's total width */
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.searchItem {
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: #000;
    transition: background-color 0.2s;
    cursor: pointer;
}

.searchItem:hover {
    background-color: #f1f1f1;
}

.searchImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 10px;
}

.searchItemName {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.searchItemPrice {
    font-size: 12px;
    color: #757575;
}
.searchWrapper {
    position: relative;
    /* existing styles */
}
.resultButton{
    background-color: #ADB5BD;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.noResults {
    padding: 10px;
    font-size: 14px;
    color: #757575; /* Gray color for the message */
    text-align: center;
}
.icon{
    cursor: pointer;
}
.cartCounter{
    background-color: red;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    padding: 2px 6px;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 29px;
}
.likedCounter{
    background-color: #c7ab3d;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    padding: 2px 6px;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 29px;
}
.counterContainer{
    position: relative;
}

.mobileSearchButton {
    display: none; /* Hidden by default */
    background: none;
    border: none;
    font-size: 22px;
    color: #000;
    cursor: pointer;
}

/* Search Modal Styling */
.searchModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.searchForm {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.searchInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.submitButton {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.closeButton {
    padding: 10px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}
.searchModalMobile{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
}
.closeIcon{
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}
.modalSearch{
    width: 100%;
    margin-top: 20px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    font-size: 18px;
    padding-left: 40px;
    padding-bottom: 10px;
}
.mobileSearchIcon{
    position: absolute;
    top: 15px;
    font-size: 30px;
    color: #000;
}

  
  @media (min-width: 850px) and (max-width: 1080px) {
     .searchWrapper {
        width: 400px;
     }
     .content{
        gap: 20px;
     }
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width:451px) and (max-width: 849px) {
    .searchWrapper {
        display: none; /* Hide search input on smaller screens */
    }

    .mobileSearchButton {
        display: block; /* Show search button on smaller screens */
        
    }
    .navigation{
        
    }
    .searchIcon{
        font-size: 50px;
    }
    .searchDropdown {
        position: relative;
    }
}

@media (max-width: 450px) {
    .searchWrapper {
        display: none; /* Hide search input on smaller screens */
    }

    .mobileSearchButton {
        display: block; /* Show search button on smaller screens */
        
    }
    .searchIcon{
        font-size: 40px;
    }
    .searchDropdown {
        position: relative;
    }
    .mainHeader{
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }
    .logo{
        width: 100px;
    }
    .content{
        margin-right: 0px;
    }
    .icon{
        width: 40px;
    }
}
/* General Styles for the Header */

/* Menu Button */
.menuButton {
    display: none;
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    color: #000;
}

/* Mobile Menu Container */
.mobileMenu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 75%;
    max-width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease;
    z-index: 1001;
    padding: 20px;
    overflow-y: auto;
}

/* Open State */
.mobileMenu.open {
    right: 0;
}

/* Close Icon */
.closeMenuIcon {
    font-size: 28px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: #000;
}

/* Mobile Navigation Links */
.mobileNavigation {
    list-style: none;
    padding: 0;
    margin: 50px 0 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mobileNavItem {
    text-align: left;
}

.mobileLink {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: #000;
}
.barsIcon{
    font-size: 35px;
    margin-left: 10px;
    display: none;
}

/* Show Menu Button Only on Small Screens */
@media (max-width: 849px) {
    .menuButton {
        display: block;
    }

    .navigation {
        display: none; /* Hide the regular navigation */
    }
    .categoryContainer{
        justify-content: start;
    }
    .barsIcon{
        display: block;
    }
}
