/* .container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  .modalContent {
    background: #fff; 
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow-y: auto;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden; 
}
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }
  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .details {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
  }
  
  .details p {
    margin-bottom: 10px;
  }
  
  .details strong {
    color: #000;
  }
  
  .productTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .productTable th,
  .productTable td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .productTable th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .productTable td {
    color: #555;
  }
  
  .loader {
    font-size: 18px;
    color: #3498db;
    text-align: center;
    margin-top: 20px;
  }
  
  .error {
    font-size: 18px;
    color: #e74c3c;
    text-align: center;
    margin-top: 20px;
  }
  
  @media (max-width: 600px) {
    .container {
      padding: 15px;
      width: 95%;
    }
  
    .title {
      font-size: 20px;
    }
  
    .details {
      font-size: 14px;
    }
  
    .productTable th,
    .productTable td {
      font-size: 14px;
      padding: 10px;
    }
  }
   */
   .container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px; /* Mírně větší zaoblení rohů */
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Zvýšený kontrast stínu */
  }
  
  .modalContent {
    background: #ffffff; /* Bílé pozadí modalu */
    padding: 25px;
    border-radius: 12px; /* Mírně větší zaoblení rohů */
    max-width: 600px;
    width: 90%;
    max-height: 80vh; /* Omezení výšky modalu na 80% výšky obrazovky */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Hlubší stín pro větší vizuální oddělení */
    position: relative;
    overflow-y: auto; /* Povolit vertikální posuvník, pokud obsah přesahuje */
    animation: fadeIn 0.3s ease-in-out; /* Jemná animace při zobrazení */
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6); /* O něco tmavší průhledné pozadí */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
    animation: overlayFadeIn 0.3s ease-in-out; /* Jemná animace při zobrazení */
  }
  
  .closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888; /* Jemnější barva tlačítka zavřít */
    transition: color 0.3s ease;
  }
  
  .closeButton:hover {
    color: #333; /* Zvýraznění při najetí myší */
  }
  
  .title {
    font-size: 26px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid #f2f2f2; /* Jemná oddělovací čára pod nadpisem */
    padding-bottom: 10px;
  }
  
  .details {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  .details p {
    margin-bottom: 12px;
  }
  
  .details strong {
    color: #000;
  }
  
  .productTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .productTable th,
  .productTable td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .productTable th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #444; /* Mírně tmavší barva textu */
  }
  
  .productTable td {
    color: #555;
  }
  
  .loader {
    font-size: 18px;
    color: #3498db;
    text-align: center;
    margin-top: 20px;
  }
  
  .error {
    font-size: 18px;
    color: #e74c3c;
    text-align: center;
    margin-top: 20px;
  }
  .containerBtn{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit{
    font-size: 26px;
    color: #333;
    transition: 0.3s ease-out;
    cursor: pointer;
  }
  .edit:hover{
    color: #6f6f6f;
  }
  .details input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .saveButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 600px) {
    .container {
      padding: 15px;
      width: 95%;
    }
  
    .title {
      font-size: 20px;
    }
  
    .details {
      font-size: 14px;
    }
  
    .productTable th,
    .productTable td {
      font-size: 14px;
      padding: 10px;
    }
  }
  
  /* Jemná animace při zobrazení */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes overlayFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  