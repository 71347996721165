.alert {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    animation: fadeIn 0.5s ease-out;
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  